import React from "react"
import { BREAKPOINTS } from "src/utils/exportFunctions"

const StylesMenu = (props) => {
    return (
        <style>{`
        .Menu .bm-burger-button {
            left: calc(100% - 44.5px);
            position: fixed;
            top: 3rem;
            transform: translateY(-50%);
            width: 32px;
        }

        .Menu .bm-cross-button {
            height: auto!important;
            left: calc(100% - 44.5px)!important;
            right: auto!important;
            top: 3rem!important;
            transform: translateY(-50%);
            width: 32px!important;
        }

        .Menu .bm-menu-wrap {
            height: 100%;
            left: auto;
            max-width: 640px;
            min-width: 320px;
            position: fixed;
            top: 0;
        }

        .Menu .bm-menu {
            background: white;
            font-size: 1.15em;
            padding: 0;
            position: relative;
        }

        .Menu .bmList {
            align-items: center;
            margin: 0 auto;
            max-width: 290px;
            padding: 75px 0 60px;
            text-align: center;
            width: 65%;
        }

        .Menu .bmList li {
            border-bottom: 1px solid #CCC;
            font-size: 1.4rem;
            letter-spacing: .35em;
            line-height: 4.4rem;
        }
        .Menu .bmList li:last-of-type {
            border-bottom: none;
        }

        .Menu .bmList li a {
            padding-left: .35em;
        }

        .Menu .bmFootLogo {
            margin: 0 auto 14px;
            width: 183px;
        }

        .Menu .bmFootCopy {
            color: #CCC;
            font-size: 1.0rem;
            font-weight: 500;
            letter-spacing: .09em;
            text-align: center;
            transform: scale(.8);
        }

        .Menu .bm-item {
            outline: none;
        }

        .Menu .bm-overlay {
            top: 60px;
        }

        @media (min-width: ${BREAKPOINTS['md']}px) {
            .Menu .bm-burger-button {
                left: calc(100% - 67px);
            }

            .Menu .bm-cross-button {
                left: calc(100% - 67px)!important;
            }

            .Menu .bmList {
                padding: 135px 0 90px;
                width: 290px;
            }

            .Menu .bmList li {
                font-size: 2.5rem;
                line-height: 9.1rem;
            }

            .Menu .bmFootLogo {
                width: 228px;
            }

            .Menu .bmFootCopy {
                transform: scale(1);
            }
        }

        @media (min-width: ${BREAKPOINTS['xl']}px) {
            .Menu .bm-burger-button {
                display: none;
            }
        }
        `}
        </style>
    );
};

export default StylesMenu;
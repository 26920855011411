
import titleAccsImg from 'src/images/title_access.svg'
import titleComImg from 'src/images/title_company.svg'
import titleSrvImg from 'src/images/title_services.svg'
import pdfPrivacy from 'src/pdfs/privacy_policy.pdf'
export const PAGE_ID_TOP   = 1;
export const PAGE_ID_ACCS  = 2;
export const PAGE_ID_COM   = 3;
export const PAGE_ID_SRV   = 4;
export const PAGE_ID_PRVCY = 5;
export const PAGE_IDS      = {
    'top': PAGE_ID_TOP, 
    'access': PAGE_ID_ACCS,
    'company': PAGE_ID_COM,
    'services': PAGE_ID_SRV,
    'privacy': PAGE_ID_PRVCY
};
export const BREAKPOINT_SM = 640;
export const BREAKPOINT_MD = 768;
export const BREAKPOINT_LG = 1024;
export const BREAKPOINT_XL = 1280;
export const BREAKPOINTS = {
    'sm': BREAKPOINT_SM,
    'md': BREAKPOINT_MD,
    'lg': BREAKPOINT_LG,
    'xl': BREAKPOINT_XL,
};

export const getJpTitle = function (pageId) {
    let jpTitle = 'TOP';
    if(pageId === PAGE_ID_ACCS){
        jpTitle = 'アクセス';
    } else if(pageId === PAGE_ID_COM){
        jpTitle = '会社概要';
    } else if(pageId === PAGE_ID_SRV) {
        jpTitle = '事業内容';
    } else if(pageId === PAGE_ID_PRVCY) {
        jpTitle = '個人情報保護方針';
    }
    return jpTitle;
}
export const getTitleSvg = function (pageId) {
    let titleImg = titleAccsImg;
    if(pageId === PAGE_ID_COM){
        titleImg = titleComImg;
    } else if(pageId === PAGE_ID_SRV) {
        titleImg = titleSrvImg;
    }
    return titleImg;
}
export const getPagesName = function (pageId) {
    let pageName = 'index';
    if(pageId === PAGE_ID_ACCS){
        pageName = 'access';
    } else if(pageId === PAGE_ID_COM){
        pageName = 'company';
    } else if(pageId === PAGE_ID_SRV) {
        pageName = 'services';
    } else if(pageId === PAGE_ID_PRVCY) {
        pageName = 'privacy';
    }
    return pageName;
}
export const getPrivacyPdf = function () {
    return pdfPrivacy;
}